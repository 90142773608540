/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';

import { Button, ModalComponent, Table } from 'sonoma-design-system';
import { Skeleton } from '@material-ui/lab';
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Avatar,
} from '@material-ui/core';
import { format } from 'date-fns';

import Divider from '../Divider';
import FieldsetTitle from '../FieldsetTitle';
import SEOFields from '../SEOFields';
import TaxFields from '../TaxFields';
import InfoFields from '../InfoFields';
import SimilarProducts from '../SimilarProducts';

import {
  fetchData,
  setForm,
  setErrorOnFill,
  fetchIntegratedProducts,
} from '../../store/informacoesForm';
import {
  setForm as setFormEdit,
  setErrorOnFill as setErrorOnFillEdit,
} from '../../store/informacoesEdit';
import productInfoDataSelector from '../../store/selectors/productInfo';

import { checkUrlExistence } from '../../services/ProductService';

import {
  BackButtonsContainer,
  Footer,
  Error,
  CenterColumn,
  ProductCard,
  ProductDescription,
  ProductLink,
  ProductCol,
  LoadingSpinner,
} from './styles';
import { ButtonDescription } from '../../styles';

import defaultWineImg from '../../assets/default-wine.png';
import { getUserRoles } from '../../auth/auth';
import { useLocalDB } from '../../hooks';

function DateColumn({ item }) {
  return (
    <CenterColumn>
      {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

function Actions({ item }) {
  const dispatch = useDispatch();
  const { pro_in_codigo } = useParams();

  const form = useSelector((state) =>
    pro_in_codigo
      ? state.product.edit.informacoes.form
      : state.product.new.informacoes.form
  );

  const isChecked = form.produtos_suplentes.find(
    (e) => e.child_pro_in_codigo === item.pro_in_codigo
  );

  const onAddCheck = useCallback(
    (checked) => {
      const produtos_suplentes = checked
        ? [
            ...form.produtos_suplentes,
            {
              parent_pro_in_codigo: pro_in_codigo,
              child_pro_in_codigo: item.pro_in_codigo,
              children: item,
            },
          ]
        : form.produtos_suplentes.filter(
            (e) => e.child_pro_in_codigo !== item.pro_in_codigo
          );

      dispatch(
        pro_in_codigo
          ? setFormEdit({
              produtos_suplentes,
            })
          : setForm({
              produtos_suplentes,
            })
      );
    },
    [dispatch, form.produtos_suplentes, item, pro_in_codigo]
  );
  return (
    <td>
      <FormGroup>
        <FormControlLabel
          value='start'
          control={
            <Checkbox
              onChange={(e) => onAddCheck(e.target.checked)}
              checked={Boolean(isChecked)}
            />
          }
          label='Vincular'
          labelPlacement='start'
        />
      </FormGroup>
    </td>
  );
}

Actions.propTypes = {
  item: PropTypes.object.isRequired,
};

function Product({ item }) {
  return (
    <ProductCol>
      <ProductCard>
        <Avatar
          variant='square'
          style={{
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #E0E0E0',
            height: '48px',
            width: '48px',
          }}
          src={item?.image_firebase_url || defaultWineImg}
          alt={item.pro_st_descricao}
        />

        <div>
          <ProductDescription title={item.pro_st_descricao}>
            {item.pro_st_descricao}
          </ProductDescription>
          <ProductLink
            href='https://www.sonoma.com.br/'
            target='_blank'
            rel='noreferrer'
          >
            Ver no site
          </ProductLink>
        </div>
      </ProductCard>
    </ProductCol>
  );
}

Product.propTypes = {
  item: PropTypes.object.isRequired,
};
export default function InformacoesForm({
  onNext,
  onPrevious,
  isEdit,
  isKit,
  publisher,
  onCreateProduct,
  isLoading,
}) {
  const { pro_in_codigo } = useParams();

  const [open, setOpen] = useState(false);
  const [urlAlreadyExist, setUrlAlreadyExist] = useState(false);

  const isDeliveryBySeller = getUserRoles().includes('DELIVERY_BY_SELLER');

  const { clear, add, data: localData } = useLocalDB('info');

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    perfis_impostos,
    origens_produto,
    codigos_fiscais,
    categorias_vitrine,
    classificacoes_produto,
    categorias,
    departamentos,
    politicas_comerciais,
    loading: loadingData,
    error: dataError,
  } = useSelector(productInfoDataSelector());

  const isDataLoadForForm =
    perfis_impostos.length > 0 &&
    codigos_fiscais.length > 0 &&
    categorias_vitrine.length > 0 &&
    classificacoes_produto.length > 0 &&
    categorias.length > 0 &&
    departamentos.length > 0 &&
    politicas_comerciais.length > 0 &&
    origens_produto.length > 0;

  const form = useSelector((state) =>
    isEdit
      ? state.product.edit.informacoes.form
      : state.product.new.informacoes.form
  );
  const loadingProduct = useSelector(
    (state) => isEdit && state.product.edit.informacoes.loading
  );
  const productError = useSelector(
    (state) => isEdit && state.product.edit.informacoes.error
  );
  const errorOnFill = useSelector((state) =>
    isEdit
      ? state.product.edit.informacoes.errorOnFill
      : state.product.new.informacoes.errorOnFill
  );

  const integratedProducts = useSelector(
    (state) => state.product.new.informacoes.integrated_products.data
  );

  const integratedPaging = useSelector(
    (state) => state.product.new.informacoes.integrated_products.paging
  );
  const integratedOrdering = useSelector(
    (state) => state.product.new.informacoes.integrated_products.ordering
  );
  const integratedFilters = useSelector(
    (state) => state.product.new.informacoes.integrated_products.filters
  );

  const cols = useMemo(
    () => [
      { id: 'pro_st_descricao', title: 'Produto', component: Product },
      { id: 'pro_st_sku', title: 'SKU' },
      { id: 'estoqueTotal', title: 'Estoque' },
      { id: 'createdAt', title: 'Data de indexação', component: DateColumn },
      { id: 'action', title: '', component: Actions },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localData) {
      dispatch(
        setForm({
          pro_st_descricao: localData.nome,
          pro_st_ean: localData.ean,
          safra: localData.safra,
          pro_st_url: localData.url,
          pro_st_tags: localData.tags,
          dep_in_codigo: localData.departmento,
          pro_st_codseller: localData.sku_seller,
          vic_in_codigo: localData.categoria_vitrine,
          cat_in_codigo: localData.categoria,
          pro_lt_metatagdesc: localData.descricao,
          pro_lt_sobre: localData.sobre,
          pro_lt_historia: localData.historia,
          clf_in_codigo: localData.codigo_fiscal,
          pei_in_codigo: localData.perfil_imposto,
          clp_in_codigo: localData.classificacao_prod,
          ori_in_codigo: localData.origem_prod,
          pro_in_score: localData.score,
          pro_bo_exibirnosite: localData.exibir_site,
          pro_bo_ativo: localData.ativo,
          pro_bo_mostraresgotado: localData.exibir_avise,
          produtos_suplentes: localData.produtos_suplentes,
        })
      );
    }
  }, [dispatch, localData]);

  const onFormChange = useCallback(
    (e) => {
      // If options choosed is 'Selecione' return null on redux
      if (e.target.value === 'Selecione') {
        dispatch(
          isEdit
            ? setFormEdit({ [e.target.name]: null })
            : setForm({ [e.target.name]: null })
        );
      } else {
        dispatch(
          isEdit
            ? setFormEdit({ [e.target.name]: e.target.value })
            : setForm({ [e.target.name]: e.target.value })
        );
      }
    },
    [dispatch, isEdit]
  );

  const onCheckbox = useCallback(
    (e) => {
      dispatch(
        isEdit
          ? setFormEdit({ [e.target.name]: e.target.checked })
          : setForm({ [e.target.name]: e.target.checked })
      );
    },
    [dispatch, isEdit]
  );

  const onSetForm = useCallback(
    (values) => {
      dispatch(isEdit ? setFormEdit({ ...values }) : setForm({ ...values }));
    },
    [dispatch, isEdit]
  );

  const onAddTag = useCallback(
    (name, value, atrName) => {
      const oldValue = form?.[name] || [];

      if (form) {
        // eslint-disable-next-line eqeqeq
        const exists = form[name].find((t) => t[atrName] == value[atrName]);

        if (exists) return;
      }

      dispatch(
        isEdit
          ? setFormEdit({ [name]: [...oldValue, value] })
          : setForm({ [name]: [...oldValue, value] })
      );
    },
    [dispatch, form, isEdit]
  );

  const loading = useMemo(
    () => loadingData || loadingProduct,
    [loadingData, loadingProduct]
  );

  const error = useMemo(
    () => dataError || productError,
    [dataError, productError]
  );

  if (loading) return <Skeleton animation='wave' height={600} />;

  if (error)
    return (
      <Error>
        Tivemos um problema ao carregar as informações, tente novamente mais
        tarde.
      </Error>
    );

  async function validationPublisher() {
    const existUrl = await checkUrlExistence(pro_in_codigo, form.pro_st_url);

    if (existUrl) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      setUrlAlreadyExist(true);
      toast.warning('URL já existente, por favor digite outra.');
      return false;
    }

    setUrlAlreadyExist(false);

    if (!form?.dep_in_codigo) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    dispatch(isEdit ? setErrorOnFillEdit(false) : setErrorOnFill(false));

    return onCreateProduct();
  }

  function validationKit() {
    if (!form?.vic_in_codigo) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    return true;
  }

  async function validationProduct() {
    const existUrl = await checkUrlExistence(pro_in_codigo, form.pro_st_url);

    if (existUrl) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      setUrlAlreadyExist(true);
      toast.warning('URL já existente, por favor digite outra.');
      return false;
    }
    setUrlAlreadyExist(false);

    if (
      !form?.dep_in_codigo ||
      !form?.pei_in_codigo ||
      !form?.clf_in_codigo ||
      !form?.vic_in_codigo ||
      !form?.clp_in_codigo ||
      !form?.cat_in_codigo ||
      form?.ori_in_codigo === null ||
      form?.ori_in_codigo === undefined
    ) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    return true;
  }

  async function validationDeliveryBySeller() {
    if (!form.pro_st_url || form.pro_st_url === '') {
      toast.warning('Por favor insira uma URL.');
      return false;
    }

    const existUrl = await checkUrlExistence(pro_in_codigo, form.pro_st_url);

    if (existUrl) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      setUrlAlreadyExist(true);
      toast.warning('URL já existente, por favor digite outra.');
      return false;
    }

    setUrlAlreadyExist(false);

    if (!form?.dep_in_codigo || !form?.vic_in_codigo || !form?.cat_in_codigo) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    return true;
  }

  async function isValid() {
    if (publisher) {
      return validationPublisher();
    }

    if (isDeliveryBySeller) {
      return validationDeliveryBySeller();
    }

    if (isKit) {
      return validationKit();
    }

    return validationProduct();
  }

  function saveLocally() {
    clear();

    add({
      nome: form.pro_st_descricao,
      ean: form.pro_st_ean,
      safra: form.safra,
      url: form.pro_st_url,
      tags: form.pro_st_tags,
      departmento: form.dep_in_codigo,
      sku_seller: form.pro_st_codseller,
      categoria_vitrine: form.vic_in_codigo,
      categoria: form.cat_in_codigo,
      descricao: form.pro_lt_metatagdesc,
      sobre: form.pro_lt_sobre,
      historia: form.pro_lt_historia,
      codigo_fiscal: form.clf_in_codigo,
      perfil_imposto: form.pei_in_codigo,
      classificacao_prod: form.clp_in_codigo,
      origem_prod: form.ori_in_codigo,
      score: form.pro_in_score,
      exibir_site: form.pro_bo_exibirnosite,
      ativo: form.pro_bo_ativo,
      exibir_avise: form.pro_bo_mostraresgotado,
      produtos_suplentes: form.produtos_suplentes,
    });
  }

  async function handleNextStep() {
    if (!(await isValid())) return false;

    saveLocally();

    return onNext();
  }

  function handleButtonDescription() {
    if (publisher) return 'Salvar';

    return 'Avançar';
  }
  function handleDeleteIntegrateProduct(item) {
    const newProdutosSuplentes = form.produtos_suplentes.filter(
      (e) => e.child_pro_in_codigo !== item.child_pro_in_codigo
    );

    dispatch(
      isEdit
        ? setFormEdit({
            produtos_suplentes: newProdutosSuplentes,
          })
        : setForm({
            produtos_suplentes: newProdutosSuplentes,
          })
    );
  }

  return isDataLoadForForm ? (
    <section>
      <FieldsetTitle>Dados Gerais e SEO</FieldsetTitle>

      <SEOFields
        form={form}
        departamentos={departamentos}
        perfis_impostos={perfis_impostos}
        codigos_fiscais={codigos_fiscais}
        categorias_vitrine={categorias_vitrine}
        categorias={categorias}
        onFormChange={onFormChange}
        onAddTag={onAddTag}
        onSetForm={onSetForm}
        errorOnFill={errorOnFill}
        publisher={publisher}
        isKit={isKit}
        urlAlreadyExist={urlAlreadyExist}
      />

      {!publisher && (
        <>
          {!isKit && !isDeliveryBySeller && (
            <>
              <FieldsetTitle>Configurações tributárias</FieldsetTitle>

              <TaxFields
                form={form}
                classificacoes_produto={classificacoes_produto}
                perfis_impostos={perfis_impostos}
                origens_produto={origens_produto}
                codigos_fiscais={codigos_fiscais}
                onFormChange={onFormChange}
                onSetForm={onSetForm}
                errorOnFill={errorOnFill}
              />
            </>
          )}

          <FieldsetTitle>Classificação</FieldsetTitle>

          <InfoFields
            form={form}
            onFormChange={onFormChange}
            politicas_comerciais={politicas_comerciais}
            onCheckbox={onCheckbox}
            onAddTag={onAddTag}
            onSetForm={onSetForm}
          />

          <FieldsetTitle>Produtos Suplentes</FieldsetTitle>

          <ModalComponent
            modalId='ModalProdSupl'
            labelButton='Vincular Produtos Suplentes'
            open={open}
            setOpen={setOpen}
          >
            {!loading && !error && (
              <Table
                refValue={integratedFilters.ref}
                rows={integratedProducts}
                columns={cols}
                ordering={integratedOrdering}
                onChangeOrder={(order) =>
                  dispatch(
                    fetchIntegratedProducts(
                      integratedPaging,
                      order,
                      integratedFilters
                    )
                  )
                }
                paging={integratedPaging}
                onChangePage={(page) =>
                  dispatch(
                    fetchIntegratedProducts(
                      { page, limit: integratedPaging.limit },
                      integratedOrdering,
                      integratedFilters
                    )
                  )
                }
                onSearch={(ref) =>
                  dispatch(
                    fetchIntegratedProducts(
                      { page: 1, limit: integratedPaging.limit },
                      { order_by: 'pro_st_descricao', order: 'asc' },
                      { ref }
                    )
                  )
                }
                searchPlaceholder='Buscar por Nome ou SKU'
              />
            )}
          </ModalComponent>

          <br />

          <SimilarProducts
            products={form.produtos_suplentes}
            handleDeleteIntegrateProduct={handleDeleteIntegrateProduct}
          />
        </>
      )}

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <BackButtonsContainer>
          <Button
            outlined
            onClick={() => history.push('/')}
            backgroundColor='#B00D1F'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>
        </BackButtonsContainer>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <BackButtonsContainer>
            <Button
              outlined
              isStartIcon
              onClick={onPrevious}
              backgroundColor='#8A8A8A'
            >
              <ButtonDescription>Voltar</ButtonDescription>
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleNextStep}
              backgroundColor='#9CD186'
            >
              <ButtonDescription>{handleButtonDescription()}</ButtonDescription>
            </Button>
          </BackButtonsContainer>
        )}
      </Footer>
    </section>
  ) : (
    <Skeleton animation='wave' height={600} />
  );
}

InformacoesForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  publisher: PropTypes.bool,
  isKit: PropTypes.bool,
  onCreateProduct: PropTypes.func,
  isLoading: PropTypes.bool,
};

InformacoesForm.defaultProps = {
  isEdit: false,
  publisher: false,
  isKit: false,
  onCreateProduct: null,
  isLoading: false,
};
