/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';

import { Skeleton } from '@material-ui/lab';
import { MdEdit } from 'react-icons/md';
import { Table, Select, Options } from 'sonoma-design-system';

import { CenterColumn, Error, Section, SelectItem } from './styles';

import { fetchAllFiliais, clearAll } from '../../store/filiais';
import { fetchAllSellers } from '../../store/sellers';

function DateColumn({ item }) {
  return (
    <CenterColumn>
      {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

function Actions({ item }) {
  const history = useHistory();

  function renderOptions() {
    return [
      {
        icon: MdEdit,
        text: 'Editar',
        onClick: () =>
          history.push(
            `/filial/edit/${item.fil_in_codigo ? 'ff' : 'dbs'}/${
              item.fil_in_codigo || item.slr_in_codigo
            }`
          ),
      },
    ];
  }

  return (
    <td>
      <Options options={renderOptions()} />
    </td>
  );
}

Actions.propTypes = {
  item: PropTypes.object.isRequired,
};

function Wrapper({ selectValue, setValue }) {
  return (
    <SelectItem>
      <Select
        selectId='sellers'
        size='small'
        label='Tipos'
        value={selectValue}
        options={[
          { value: 'Sellers', label: 'Sellers' },
          { value: 'Filiais', label: 'Filiais' },
        ]}
        handleChange={(e) => setValue(e.target.value)}
      />
    </SelectItem>
  );
}

Wrapper.propTypes = {
  selectValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default function SettingsPage() {
  const dispatch = useDispatch();

  const [type, setType] = useState('Filiais');

  const filiais = useSelector((state) => state.filiais.filiais);
  const sellers = useSelector((state) => state.sellers.sellers);
  const loading = useSelector((state) =>
    type === 'Filiais' ? state.filiais.loading : state.sellers.loading
  );
  const error = useSelector((state) =>
    type === 'Filiais' ? state.filiais.error : state.sellers.error
  );

  const paging = useSelector((state) =>
    type === 'Filiais' ? state.filiais.paging : state.sellers.paging
  );
  const ordering = useSelector((state) =>
    type === 'Filiais' ? state.filiais.ordering : state.sellers.ordering
  );
  const filters = useSelector((state) =>
    type === 'Filiais' ? state.filiais.filters : state.sellers.filters
  );

  useEffect(() => {
    dispatch([
      fetchAllFiliais({ page: 1, limit: '15' }),
      fetchAllSellers({ page: 1, limit: '15' }),
    ]);
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  const filialCols = useMemo(
    () => [
      { id: 'fil_st_nome', title: 'Nome' },
      { id: 'fil_st_cnpj', title: 'CNPJ' },
      { id: 'fil_st_prefixo', title: 'Prefixo' },
      { id: 'fil_st_apelido', title: 'Apelido' },
      { id: 'fil_in_days_prepare_ship', title: 'Preparo Envio' },
      { id: 'createdAt', title: 'Data de criação', component: DateColumn },
      { id: 'action', title: '', component: Actions },
    ],
    []
  );

  const sellerCols = useMemo(
    () => [
      { id: 'slr_st_descricao', title: 'Nome' },
      { id: 'slr_st_cnpj', title: 'CNPJ' },
      { id: 'slr_st_prefixo', title: 'Prefixo' },
      { id: 'slr_st_apelido', title: 'Apelido' },
      { id: 'createdAt', title: 'Data de criação', component: DateColumn },
      { id: 'action', title: '', component: Actions },
    ],
    []
  );

  function handleOnPageChange(page) {
    if (type === 'Filiais') {
      dispatch(
        fetchAllFiliais({ page, limit: paging.limit }, ordering, filters)
      );
    } else {
      dispatch(
        fetchAllSellers({ page, limit: paging.limit }, ordering, filters)
      );
    }
  }

  function handleOnSearch(ref) {
    if (type === 'Filiais') {
      dispatch(
        fetchAllFiliais(
          { page: 1, limit: paging.limit },
          { order_by: ordering.order_by, order: 'asc' },
          { ...filters, ref }
        )
      );
    } else {
      dispatch(
        fetchAllSellers(
          { page: 1, limit: paging.limit },
          { order_by: ordering.order_by, order: 'asc' },
          { ...filters, ref }
        )
      );
    }
  }

  return (
    <Section style={{ marginTop: '130px' }}>
      {loading && <Skeleton animation='wave' height={300} />}

      {error && <Error>Tivemos um problema ao buscar as filiais.</Error>}

      {!loading && !error && (
        <Table
          headerTitle='Configurações de filiais e sellers'
          refValue={filters.ref}
          rows={type === 'Filiais' ? filiais : sellers}
          columns={type === 'Filiais' ? filialCols : sellerCols}
          ordering={ordering}
          //   onChangeOrder={(order) => dispatch(fetchAll(paging, order, filters))}
          paging={paging}
          onChangePage={(page) => handleOnPageChange(page)}
          onSearch={(ref) => handleOnSearch(ref)}
          searchPlaceholder='Buscar por Nome ou SKU'
          headerComponent={() => (
            <Wrapper selectValue={type} setValue={setType} />
          )}
        />
      )}
    </Section>
  );
}
