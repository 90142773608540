import { Types } from './sales.action';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order_by: null,
    order: 'asc',
    availableOrderby: [],
  },
  filters: {
    active: null,
    productId: null,
    ref: '',
  },
};

const setData = (state, action) => ({
  ...state,
  data: action.payload,
});

const setPaging = (state, action) => ({
  ...state,
  paging: action.payload,
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: action.payload,
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

export default function salesListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_DATA:
      return setData(state, action);
    case Types.SET_PAGING:
      return setPaging(state, action);
    case Types.SET_ORDERING:
      return setOrdering(state, action);
    case Types.SET_FILTERS:
      return setFilters(state, action);
    case Types.SET_LOADING:
      return setLoading(state, action);
    case Types.SET_ERROR:
      return setError(state, action);
    case Types.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
