import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Input, Button } from 'sonoma-design-system';

import Divider from '../../components/Divider';

import { Footer, Section, InputContainer } from './styles';

import {
  fetchFilialById,
  setForm as setFilialSeller,
  clearAll as clearFiliais,
} from '../../store/filiais';
import {
  fetchSellerById,
  setForm as setSellerForm,
  clearAll as clearSellers,
} from '../../store/sellers';
import { updateFilial } from '../../services/FilialService';
import { updateSeller } from '../../services/SellerService';

export default function EditApelidoPrefixo() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id, type } = useParams();

  const [loading, setLoading] = useState(false);

  const filial = useSelector((state) => state.filiais.filial);
  const seller = useSelector((state) => state.sellers.seller);

  useEffect(() => {
    if (id) {
      if (type === 'ff') {
        dispatch(fetchFilialById(id));
      } else {
        dispatch(fetchSellerById(id));
      }
    }

    return () => {
      dispatch([clearFiliais(), clearSellers()]);
    };
  }, [dispatch, id, type]);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (type === 'ff') {
      await updateFilial(filial);
    } else {
      await updateSeller(seller);
    }

    setLoading(false);
    history.push('/configuracoes');
  }, [filial, history, seller, type]);

  const onFormChange = useCallback(
    (e) => {
      dispatch(
        type === 'ff'
          ? setFilialSeller({ [e.target.name]: e.target.value })
          : setSellerForm({ [e.target.name]: e.target.value })
      );
    },
    [dispatch, type]
  );

  if (!filial && !seller)
    return (
      <Skeleton animation='wave' height={300} style={{ marginTop: '130px' }} />
    );

  return (
    <Section style={{ marginTop: '130px' }}>
      <h1 style={{ color: '#3B3B3A', fontSize: '24px' }}>
        Editar filial/seller
      </h1>
      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Grid spacing={2} container className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  disabled
                  inputId='fil_st_nome'
                  size='small'
                  label='Nome'
                  value={filial?.fil_st_nome || seller?.slr_st_descricao || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>

          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  disabled
                  inputId='fil_st_cnpj'
                  size='small'
                  label='CNPJ'
                  value={filial?.fil_st_cnpj || seller?.slr_st_cnpj || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>

          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId={type === 'ff' ? 'fil_st_prefixo' : 'slr_st_prefixo'}
                  size='small'
                  label='Prefixo'
                  value={filial?.fil_st_prefixo || seller?.slr_st_prefixo || ''}
                  handleChange={onFormChange}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId={type === 'ff' ? 'fil_st_apelido' : 'slr_st_apelido'}
                  size='small'
                  label='Apelido'
                  value={filial?.fil_st_apelido || seller?.slr_st_apelido || ''}
                  handleChange={onFormChange}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId={
                    type === 'ff'
                      ? 'fil_in_days_prepare_ship'
                      : 'slr_st_days_prepare_ship'
                  }
                  size='small'
                  label='Dias de preparo'
                  value={
                    filial?.fil_in_days_prepare_ship ||
                    seller?.slr_st_days_prepare_ship ||
                    ''
                  }
                  handleChange={onFormChange}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <Button
          outlined
          onClick={() => history.push('/configuracoes')}
          backgroundColor='#B00D1F'
        >
          Cancelar
        </Button>

        <Button disabled={loading} onClick={handleSubmit}>
          {loading ? <CircularProgress size='16px' /> : 'Salvar Alterações'}
        </Button>
      </Footer>
    </Section>
  );
}

EditApelidoPrefixo.propTypes = {};
